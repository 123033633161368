//############################ JS ############################

// jQuery
require('jquery/dist/jquery.js');
// Popper.js
require('popper.js/dist/umd/popper.js');
// Bootstrap
require('bootstrap/dist/js/bootstrap.js');
// Toastr
require('toastr/toastr.js');
// Formvalidation
require('imports-loader?define=>false!./js/formvalidation/js/formValidation.js');
require('imports-loader?define=>false!./js/formvalidation/js/framework/bootstrap4.js');
require('imports-loader?define=>false!./js/formvalidation/js/mandatoryicon.js');
// JavaScript Cookie
require('js-cookie/src/js.cookie.js');
// CoreUI application
require('@coreui/coreui/dist/js/coreui.js');
require('@coreui/coreui/dist/js/coreui-utilities.js');
// Jquery ui
require('jquery-ui/ui/core.js');
require('jquery-ui/ui/widget.js');
//clipboard
require('clipboard/dist/clipboard.js');
// Infinite scroll
require('infinite-scroll/dist/infinite-scroll.pkgd.js');
// Date/time picker
require('imports-loader?define=>false!./js/picker/picker.js');
require('imports-loader?define=>false!./js/picker/picker-date.js');
require('imports-loader?define=>false!./js/picker/picker-time.js');
//File Upload
require('blueimp-file-upload/js/jquery.fileupload.js');
// Codemirror
require('codemirror/lib/codemirror.js');
require('codemirror/mode/htmlembedded/htmlembedded.js');
// Summernote
require('summernote/dist/summernote-bs4.js');
// Chart.js
require('chart.js/src/chart.js');
//numeral
require('numeral/numeral.js');
// Project
require('./js/project/project.js');
require('./js/project/notifications.js');
require('./js/project/alert.js');
require('./js/project/web_push.js');
require('./js/project/modal.js');
require('./js/project/newsletter.js');
require('./js/init.js');

//############################ CSS ############################

// Font Awesome
require('@fortawesome/fontawesome-pro/scss/fontawesome.scss');
require('@fortawesome/fontawesome-pro/scss/solid.scss');
require('@fortawesome/fontawesome-pro/scss/light.scss');
require('@fortawesome/fontawesome-pro/scss/brands.scss');
require('font-awesome-animation/dist/font-awesome-animation.css');
// Animate
require('animate.css/animate.css');
// Toastr
require('toastr/toastr.scss');
// Formvalidation
require('./js/formvalidation/css/formValidation.css');
// Project
require('./scss/project.scss');

//File Upload
require('blueimp-file-upload/css/jquery.fileupload.css');

// Codemirror
require('codemirror/lib/codemirror.css');
// Summernote
require('summernote/dist/summernote-bs4.css');
var Project = require("./project");

Project.WebPush = {};
Project.WebPush.enabled = false;
Project.WebPush.init = function () {
    if ('serviceWorker' in navigator) {
        console.log("Registering service worker...");
        navigator.serviceWorker.register('./service-worker.js')
            .then(Project.WebPush.initializeState());
    } else {
        Project.WebPush.setStatus("Web push notifications aren't supported in this browser (only available for Chrome and Firefox).");
    }
};
Project.WebPush.setStatus = function (status) {
    $('#web-push-status').html(status);
};
Project.WebPush.showSubscribeButton = function () {
    $('#subscribe-button').show();
    $('#unsubscribe-button').hide();
};
Project.WebPush.showUnsubscribeButton = function () {
    $('#subscribe-button').hide();
    $('#unsubscribe-button').show();
};
Project.WebPush.enable = function () {
    Notification.requestPermission(function(result) {
        if (result === 'denied') {
            Project.WebPush.setStatus('Notifications have been disabled. Update notifications settings in your browser te receive notifications.');
        } else if (result === 'default') {
            Project.WebPush.setStatus("The permission request was dismissed. Update notifications settings in your browser te receive notifications.");
        } else {
            console.log("Notifications have been authorized. We'll be able to send notifications.");
            Project.WebPush.subscribe();
        }
    });
};
Project.WebPush.initializeState = function () {
    console.log('Initialize service worker state...');
    // Are Notifications supported in the service worker?
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        Project.WebPush.setStatus("Web push notifications aren't supported in this browser (only available for Chrome and Firefox).");
        return;
    }

    // Check the current Notification permission.
    // If its denied, it's a permanent block until the
    // user changes the permission
    if (Notification.permission === 'denied') {
        Project.WebPush.setStatus('Notifications have been disabled. Update notifications settings in your browser te receive notifications.');
        return;
    }

    // Check if push messaging is supported
    if (!('PushManager' in window)) {
        Project.WebPush.setStatus("Web push notifications aren't supported in this browser (only available for Chrome and Firefox).");
        return;
    }

    console.log("Is service worker ready?");

    // We need the service worker registration to check for a subscription
    navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
        // Do we already have a push message subscription?
        serviceWorkerRegistration.pushManager.getSubscription()
            .then(function(subscription) {
                console.log('Subscription', subscription);
                if (!subscription) {
                    // We aren’t subscribed to push, so set UI
                    // to allow the user to enable push
                    Project.WebPush.setStatus("Web push notifications are not enabled yet.");
                    Project.WebPush.showSubscribeButton();
                    return;
                }

                // Keep your server in sync with the latest subscription
                Project.WebPush.sendSubscriptionToServer(subscription).then(function () {
                    Project.WebPush.enabled = true;
                    Project.WebPush.setStatus("Web push notifications are enabled.");
                    Project.WebPush.showUnsubscribeButton();
                });
            })
            .catch(function(error) {
                console.error('Error during getSubscription()', error);
            });
    });
};
Project.WebPush.sendSubscriptionToServer = function (subscription) {
    var auth = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))));
    var p256dh = btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))));

    return $.ajax({
        url: '/ajax/web_push/subscribe',
        type: 'post',
        data: { endpoint: subscription.endpoint, auth: auth, p256dh: p256dh }
    });
};
Project.WebPush.deleteSubscriptionFromServer = function (subscription) {
    return $.ajax({
        url: '/ajax/web_push/unsubscribe',
        type: 'post',
        data: { endpoint: subscription.endpoint }
    });
};
Project.WebPush.unsubscribe = function () {
    navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
        // To unsubscribe from push messaging, you need get the
        // subcription object, which you can call unsubscribe() on.
        serviceWorkerRegistration.pushManager.getSubscription().then(
            function(subscription) {
                // Check we have a subscription to unsubscribe
                if (!subscription) {
                    // No subscription object, so set the state
                    // to allow the user to subscribe to push
                    Project.WebPush.enabled = false;
                    return;
                }

                // We have a subscription, so call unsubscribe on it
                subscription.unsubscribe().then(function() {
                    Project.WebPush.deleteSubscriptionFromServer(subscription).then(function () {
                        Project.WebPush.enabled = false;
                        Project.WebPush.setStatus("Web push notifications are disabled now.");
                        Project.WebPush.showSubscribeButton();
                    });
                }).catch(function(e) {
                    // We failed to unsubscribe, this can lead to
                   // an unusual state, so may be best to remove
                    // the subscription id from your data store and
                    // inform the user that you disabled push

                    console.log('Unsubscription error: ', e);
                });
            }).catch(function(e) {
                console.log('Error thrown while unsubscribing from ' + 'push messaging.', e);
        });
    });
};

Project.WebPush.is_subscribed = function() {
    return navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
        console.log('Service worker ready');
        serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: Project.WebPush.urlBase64ToUint8Array(WEB_PUSH)
        })
            .then(function(subscription) {
                console.log('Subscribed to service worker', subscription);
                return true;
            })
            .catch(function(e) {
                if (Notification.permission === 'denied') {
                    // The user denied the notification permission which
                    // means we failed to subscribe and the user will need
                    // to manually change the notification permission to
                    // subscribe to push messages
                } else {
                    // A problem occurred with the subscription, this can
                    // often be down to an issue or lack of the gcm_sender_id
                    // and / or gcm_user_visible_only
                    console.log('Unable to subscribe to push.', e);
                }
                return false;
            });

    });

};

Project.WebPush.subscribe = function() {
    navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
        console.log('Service worker ready');
        serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: Project.WebPush.urlBase64ToUint8Array(WEB_PUSH)
        })
            .then(function(subscription) {
                console.log('Subscribed to service worker', subscription);
                Project.WebPush.sendSubscriptionToServer(subscription).then(function () {
                    Project.WebPush.enabled = true;
                    Project.WebPush.setStatus("Web push notifications are enabled.");
                    Project.WebPush.showUnsubscribeButton();
                });
            })
            .catch(function(e) {
                if (Notification.permission === 'denied') {
                    // The user denied the notification permission which
                    // means we failed to subscribe and the user will need
                    // to manually change the notification permission to
                    // subscribe to push messages
                    Project.WebPush.setStatus('Notifications have been disabled. Update notifications settings in your browser te receive notifications.');
                } else {
                    // A problem occurred with the subscription, this can
                    // often be down to an issue or lack of the gcm_sender_id
                    // and / or gcm_user_visible_only
                    console.log('Unable to subscribe to push.', e);
                }
            });
    });
};
Project.WebPush.urlBase64ToUint8Array = function(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};
var Project = require("./project");

Project.Newsletter = {};
Project.Newsletter.subscribe = function (data) {
    var params = {};
    for (var i = 0; i < data.length; i++) {
        params[data[i]['name']] = data[i]['value'];
    }

    return $.post('/ajax/subscribe', params, 'json');
};
var Project = require("./project");

Project.Modal = {};
Project.Modal.load = function (url) {
    return $.get(url);
};
Project.Modal.showFlash = function (message, type, icon) {
    type = type || 'danger';
    icon = icon || 'warning';
    var html = '<div class="flash"><div class="blink alert alert-' + type +  '" role="alert">' +
        '<i class="fa fa-' + icon + ' text-' + type +  '"></i> ' + message + ' </div></div>';

    $('.modal.in').find('.modal-body').prepend(html);
};
Project.Modal.hideFlash = function () {
    $('.modal.in .flash').remove();
};
Project.Modal.open = function (options) {
    var $target = (options.target) ? $(options.target) : $('#generic-modal');
    if (options.href) {
        $target.attr('href', options.href);
    }
    if (options.size) {
        $target.data('modal-size', options.size);
    }
    if (options.class) {
        $target.data('class', options.class);
    }
    $target.modal('show');
};
Project.Modal.init = function () {
    //Modal opening.
    $(document).on('show.bs.modal', '.modal', function (event) {
        var $modal = $(this);
        var $options;
        if (event.relatedTarget) {
            $options = $(event.relatedTarget);
        } else {
            $options = $modal;
        }

        if ($options.data('class')) {
            $modal.addClass($options.data('class')).data('class', $options.data('class'));
        }
        if ($options.data('modal-size')) {
            $modal.data('modal-size', $options.data('modal-size'))
                .find('.modal-dialog').addClass($options.data('modal-size'));
        }
        if ($options.attr('href')) {
            Project.Modal.load($options.attr('href'))
                .then(function (response) {
                    $modal.data('initial-content', $modal.find('.modal-content').html());
                    $modal.addClass('loaded').find('.modal-content').html(response);
                    $modal.trigger($.Event('loaded.bs.modal'));
                }).catch(function (xhr) {
                    let url = '/html/whoops';
                    if (xhr.status === 401) {
                        url = '/html/auth';
                    }
                    Project.Modal.load(url)
                        .then(function (response) {
                            $modal.data('initial-content', $modal.find('.modal-content').html());
                            $modal.addClass('loaded').find('.modal-content').html(response);
                            $modal.trigger($.Event('loaded.bs.modal'));
                        });
                });
        }
    }).on('hidden.bs.modal', '.modal', function () {
        var $modal = $(this);

        //Resets modal.
        $modal.removeData("bs.modal");
        if ($modal.data('class')) {
            $modal.removeClass($modal.data('class'));
        }
        if ($modal.data('modal-size')) {
            $modal.find('.modal-dialog').removeClass($modal.data('modal-size'));
        }
        if ($modal.hasClass('loaded')) {
            $modal.find('.modal-content').html($modal.data('initial-content'));
            $modal.removeClass('loaded').removeAttr('href').removeAttr('style');
        }
    });
};
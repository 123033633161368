var Project = require("./project");

Project.Notifications = {};
Project.Notifications.toast = function (type, message, title, options) {
    return toastr[type](message, title, options);
};
Project.Notifications.error = function (message) {
    return toastr['error'](message);
};
Project.Notifications.success = function (message) {
    return toastr['success'](message);
};
Project.Notifications.info = function (message) {
    return toastr['info'](message);
};
Project.Notifications.warning = function (message) {
    return toastr['warning'](message);
};
Project.Notifications.poll = function () {
    $.get('/ajax/notification/poll').then(function (response) {
        for (let i = 0; i < response.notifications.length; i++) {
            const n = response.notifications[i];
            if ($('#notification-' + n.id).length === 0) {
                const options = {
                    timeOut: 7000,
                    closeButton: true,
                    progressBar: true,
                    iconClass: 'toast-' + n.type,
                    positionClass: 'toast-' + n.options.position,
                    onclick: function () {
                        $.post('/ajax/notification/displayed', { id: n.id })
                            .then(function () {
                                window.location.href = n.options.url;
                            });
                    },
                    onCloseClick: function () {
                        $.post('/ajax/notification/displayed', { id: n.id });
                    },
                    onHidden: function () {
                        $.post('/ajax/notification/displayed', { id: n.id });
                    }
                };
                setTimeout(function () {
                    const toast = Project.Notifications.toast(n.type, n.message, n.title, options);
                    toast.attr('id', 'notification-' + n.id);
                }, i * 3000);
            }
        }
    });
};
Project.Notifications.init = function () {
    $.get('/ajax/user').then(function (response) {
        if (response.signed_in) {
            setTimeout(function () {
                setInterval(function () {
                    if (!document.hidden) {
                        Project.Notifications.poll();
                    }
                }, 15000);

                Project.Notifications.poll();
            }, 5000);

        }
    });
};